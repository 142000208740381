//member page
import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/LinkResolver'
import { Card, Row, Col, Container } from 'react-bootstrap'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

const RememberTemplate = ({ data }) => {
  if (!data) return null

  const reMemberContent = data.prismicRemember
  const remember = reMemberContent.data || {}

  const { lang, type, url } = reMemberContent
  const alternateLanguages = reMemberContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicMenuLink || {}
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={remember.name.text} lang={lang} />
      <Container className="remember_wrapper">
        <div className="collection-page-title">
          <h1>Professional Portfolio</h1>
        </div>
        <hr />
        <Row className="mt-2 mb-5">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title className="d-flex justify-content-between bg-secondary bg-gradient text-white p-2 shadow">
                  {remember.name.text}
                  <h5>
                    {remember.registration_number_label}
                    <span>: {remember.code.text}</span>
                  </h5>
                </Card.Title>
                <hr />
                <div className="card-text">
                  <ul className="card-list_wrapper">
                    <li className="card-list-item fw-bolder">
                      {remember.registration_date_label}:
                      <span> {remember.registration_date}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {remember.status_valid_until_label}:
                      <span> {remember.status_valid_until}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {remember.membership_grade_label}:
                      <span> {remember.membership_grade.text}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {remember.area_label}:<span> {remember.area.text}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {remember.link_to_apdp_label}:
                      <a
                        className="text-primary"
                        href={remember.link_to_apdp.url}
                        target={remember.link_to_apdp.target}
                      >
                        {' '}
                        link
                      </a>
                    </li>
                    --------------------------------
                    <li className="card-list-item fw-bolder">
                      Email: <span>{remember.e_mail.text}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {remember.university_label}:
                      <span> {remember.university.text}</span>
                    </li>
                    <li className="card-list-item fw-bolder">
                      {remember.country_label}:<span> {remember.country}</span>
                    </li>
                  </ul>
                  <span className="card-qr-code_wrapper">
                    <img
                      className="qr-code"
                      src={remember.qr_code.text}
                      alt="qr-code"
                      width="100px"
                    />
                  </span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query rememberQuery($id: String, $lang: String) {
    prismicRemember(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        area_label
        area {
          text
        }
        registration_number_label
        code {
          text
        }
        e_mail {
          text
        }
        id {
          text
        }
        membership_grade_label
        membership_grade {
          text
        }
        status_label
        name_label
        name {
          text
        }
        qr_code {
          text
        }
        registration_date_label
        registration_date(formatString: "DD/MM/YYYY")
        status_valid_until_label
        status_valid_until(formatString: "DD/MM/YYYY")
        university_label
        university {
          text
        }
        status
        country
        country_label
        link_to_apdp_label
        link_to_apdp {
          url
          target
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`

export default withPrismicPreview(RememberTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
